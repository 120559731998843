export default [
  {
    path: '/tg-stars',
    name: 'tg-stars',
    component: () => import('@/views/apps/tg-stars/TGStarsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'revenue-page',
      pageTitle: 'TG Stars',
      breadcrumb: [
        {
          text: 'view',
        },
      ],
    },
  },
]
