export default [
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/apps/analytics/AnalyticsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'trackable-links',
      pageTitle: 'Analytics keys',
      breadcrumb: [
        {
          text: 'Analytics keys',
        },
      ],
    },
  },

  {
    name: 'analytics-create',
    path: '/analytics/create',
    component: () => import('@/views/apps/analytics/AnalyticsEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'trackable-links',
      breadcrumb: [
        {
          text: 'Analytics keys',
          active: true,
          to: '/analytics/create',
        },
        {
          text: 'Create',
        },
      ],
    },
  },

  {
    name: 'analytics-edit',
    path: '/analytics/edit/:id',
    component: () => import('@/views/apps/analytics/AnalyticsEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'trackable-links',
      breadcrumb: [
        {
          text: 'Analytics keys',
          active: true,
          to: '/analytics/edit',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
