import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  keys: {
    items: [],
    count: 3,
  },
})

const getters = {
  keys: store => store.keys,
}

const actions = {
  fetchTrackableLinks({ commit }, { limit = 30, page = 1, search = '' } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/trackable-links', params }).then(response => {
      commit('setNewItems', { payload: response.data, params })

      return response
    })
  },

  fetchTrackableLink(store, id) {
    return axios({ method: 'GET', url: `/trackable-links/${id}` }).then(response => response)
  },

  createTrackableLink(store, data) {
    return axios({ method: 'POST', url: '/trackable-links', data }).then(response => response)
  },

  editTrackableLink(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/trackable-links/${id}`, data: form })
  },

  deleteTrackableLinks({ commit }, { id }) {
    return axios({ method: 'DELETE', url: `/trackable-links/${id}` }).then(response => {
      commit('removeItem', id)

      return response
    })
  },

  uploadCSVTrackableLinks(store, formData) {
    return axios({ method: 'PUT', url: '/trackable-links/upload-file', data: formData })
  },
}

const mutations = {
  setNewItems(state, { payload, params }) {
    const { limit, page } = params || []

    state.keys.items = payload.items || []
    state.keys.count = payload.total
    state.keys.limit = limit
    state.keys.page = page
  },
  removeItem(state, id) {
    state.keys.items = [...state.keys.items.filter(item => item.id !== id)]
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
