export default [
  {
    path: '/hold-purchases',
    name: 'hold-purchases',
    component: () => import('@/views/apps/hold-purchases/holdPurchasesView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Hold Purchases',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
