const dateLongOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
}
export function formattedDate(dateString, config = dateLongOptions) {
  const date = new Date(dateString)

  return new Intl.DateTimeFormat('en', config).format(date)
}

const dateShortOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}
export function formattedShortDate(dateString) {
  return formattedDate(dateString, dateShortOptions)
}

const timeShortOptions = {
  hour: '2-digit',
  minute: '2-digit',
}

export function formattedShortTime(dateString) {
  return formattedDate(dateString, timeShortOptions)
}

export function formatLongString(str) {
  if (!str) return ''

  return `${str.slice(0, 4)}...${str.slice(-4)}`
}

export function formatLongStringOnStart(str, maxLength = 8) {
  if (!str || str.length <= maxLength) return str
  return `...${str.slice(-maxLength)}`
}

export function generateRandomHexColor() {
  // Generate random values between 0 and 255 for each color component
  const r = Math.floor(Math.random() * 256) // Red
  const g = Math.floor(Math.random() * 256) // Green
  const b = Math.floor(Math.random() * 256) // Blue

  // Convert each component to a hexadecimal string and return the combined HEX color
  return (
    `#${
      [r, g, b]
        .map(c => {
          const hex = c.toString(16)
          return hex.length == 1 ? `0${hex}` : hex // Ensure 2 digits by adding a leading zero if needed
        })
        .join('')}`
  )
}

export function nFormat(number, locales = 'en', maximumFractionDigits = 1) {
  const formatter = Intl.NumberFormat(locales, {
    notation: 'compact',
    maximumFractionDigits,
  })

  return formatter.format(number)
}

export function secondsToReadableFormat(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let result = ''
  if (hours > 0) {
    result += `${hours}h ${minutes.toString().padStart(2, '0')}m `
  } else if (minutes > 0) {
    result += `${minutes}m `
  }
  result += `${remainingSeconds.toString().padStart(2, '0')}s`

  return result
}

export function isTokenExpires(token) {
  if (!token) return true

  const expiresTime = localStorage.getItem('expires_at') * 1000
  const dateNow = new Date().getTime()
  return dateNow > expiresTime
}

export const getStartOfTheDayFromTimestamp = timestamp => {
  if (!timestamp) {
    return timestamp
  }
  const date = new Date(timestamp)
  date.setHours(0, 0, 0, 0)
  return date.getTime()
}

export const getEndOfTheDayFromTimestamp = timestamp => {
  if (!timestamp) {
    return timestamp
  }
  const date = new Date(timestamp)
  date.setHours(23, 59, 59, 999)
  return date.getTime()
}
