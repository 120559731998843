export default [
  {
    path: '/cefi-withdraw',
    name: 'cefi-withdraw',
    component: () => import('@/views/apps/cefi-withdraw/CefiWithdrawView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'revenue-page',
      pageTitle: 'CeFi Withdraw',
      breadcrumb: [
        {
          text: 'view',
        },
      ],
    },
  },
]
