import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  fee: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  fee: store => store.fee,
}

const actions = {
  fetchFee({ commit }) {
    return axios({ method: 'GET', url: '/cefi-fees' }).then(response => {
      commit('setFee', { fee: response?.data })

      return response
    })
  },

  fetchFeeById(store, id) {
    return axios({ method: 'GET', url: `/cefi-fees/${id}` })
  },

  updateFee(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/cefi-fees/${id}`, data: { ...form } })
  },

  fetchFeeAggregationsByPeriod(store, { from, to }) {
    const requestQuery = {
      from,
      to,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/cefi-fees/fee-aggregations-by-period', params })
  },

  fetchFeeAggregationsAllTime() {
    return axios({ method: 'GET', url: '/cefi-fees/fee-aggregations-all-time' })
  },
}

const mutations = {
  setFee(state, { fee } = {}) {
    state.fee.value = fee.data || []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
