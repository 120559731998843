import axios from '@/plugins/axios'

const state = () => ({
  rate: [],
})

const getters = {
  rate: store => store.rate,
}

const actions = {
  fetchExchangeRate({ commit }) {
    return axios({ method: 'GET', url: '/exchange-rates' }).then(response => {
      commit('setRate', response?.data)
      return response
    })
  },
}

const mutations = {
  setRate(state, rate) {
    state.rate = rate
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
